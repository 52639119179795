import React from 'react'
import styled from 'styled-components'

import Container from 'components/shared/container'
import { Text } from 'components/shared/typography'

import useBreakpoint from 'hooks/useBreakpoint'

type Props = {
  description: string
}

const Section = styled.section`
  width: 100%;
  padding: 40px 0 20px;
  background-color: ${({ theme }) => theme.colors.gray100};

  ${({ theme }) => theme.media.lg.min} {
    padding: 0;
    background-color: ${({ theme }) => theme.colors.white};
  }
`

const About: React.FC<Props> = ({ description }) => {
  const { lg } = useBreakpoint()

  return (
    <Section>
      <Container>
        <Text
          size={lg ? 17 : 16}
          weight={500}
          themecolor="black"
          dangerouslySetInnerHTML={{ __html: description }}
        />
      </Container>
    </Section>
  )
}

export default About
