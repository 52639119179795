import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

import Container from 'components/shared/container'
import LazyImage from 'components/shared/lazyImage'
import { Heading, Text } from 'components/shared/typography'
import { ImageDataLike } from 'gatsby-plugin-image'

import useBreakpoint from 'hooks/useBreakpoint'

type Collection = {
  img: ImageDataLike
  alt: string
  name: string
  description: string
  link: string
}

type Props = {
  collections: Collection[]
}

const Section = styled.section`
  width: 100%;
  padding: 20px 0 0;
  background-color: ${({ theme }) => theme.colors.gray100};

  ${({ theme }) => theme.media.lg.min} {
    padding: 80px 0;
    background-color: ${({ theme }) => theme.colors.white};
  }
`

const CollectionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${({ theme }) => theme.media.lg.min} {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
  }
`

const CollectionSingleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  max-width: 300px;
  max-height: 250px;
  margin-bottom: 30px;

  ${({ theme }) => theme.media.lg.min} {
    max-width: 280px;
    max-height: 220px;
    margin: 0 30px 50px 0;

    & > div:first-child {
      min-height: 145px;
    }
  }
`

const CollectionSingleInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;

  ${({ theme }) => theme.media.lg.min} {
    width: 85%;
  }
`

const Hr = styled.div`
  width: 100%;
  height: 8px;
  margin: 10px 0 10px;
  background-color: ${({ theme }) => theme.colors.primary};
  border-radius: 5px;
`

const Collections: React.FC<Props> = ({ collections }) => {
  const { lg } = useBreakpoint()

  return (
    <Section title="Ogrodzenia">
      <Container>
        <CollectionsWrapper>
          {collections.map(({ img, alt, name, description, link }) => (
            <CollectionSingleWrapper key={name} as={Link} to={link}>
              <LazyImage src={img} alt={alt} objectFit="contain" />
              <CollectionSingleInnerWrapper>
                <Hr />
                <Heading
                  as="h6"
                  size={lg ? 18 : 20}
                  themecolor="black"
                  transform="uppercase"
                  align="center"
                  dangerouslySetInnerHTML={{ __html: name }}
                />

                <Text
                  size={14}
                  weight={lg ? 400 : 500}
                  themecolor="black100"
                  align="center"
                  dangerouslySetInnerHTML={{ __html: description }}
                />
              </CollectionSingleInnerWrapper>
            </CollectionSingleWrapper>
          ))}
        </CollectionsWrapper>
      </Container>
    </Section>
  )
}

export default Collections
